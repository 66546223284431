import { ComponentProps } from 'react'

export function MobileAppLogo(props: ComponentProps<'svg'>) {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="64" height="64" rx="8" fill="url(#paint0_linear_5_3293)" />
      <rect
        x="0.25"
        y="0.25"
        width="63.5"
        height="63.5"
        rx="7.75"
        stroke="#222222"
        strokeOpacity="0.0705882"
        strokeWidth="0.5"
      />
      <path
        d="M46.3915 13.8946C46.4586 12.7194 46.6187 11.9442 46.9405 11.2364C47.0679 10.9562 47.1872 10.7268 47.2057 10.7268C47.2241 10.7268 47.1687 10.9337 47.0826 11.1864C46.8486 11.8734 46.8102 12.8131 46.9714 13.9063C47.176 15.2935 47.2923 15.4936 48.7649 16.992C49.4557 17.6948 50.2591 18.5813 50.5503 18.9618L51.0799 19.6538L50.5503 19.1593C49.9027 18.5545 48.4133 17.3751 48.0843 17.2065C47.8637 17.0934 47.8309 17.0954 47.6948 17.2302C47.5695 17.3545 47.543 17.5412 47.5256 18.4238C47.4985 19.7993 47.3102 20.6822 46.8557 21.5651C46.6099 22.0426 46.5711 21.9407 46.7936 21.4017C46.9597 20.9993 46.9765 20.8224 46.9753 19.4908C46.9727 16.8152 46.6537 16.172 44.7826 15.0701C44.3086 14.7909 43.5276 14.3884 43.0471 14.1754C42.5666 13.9625 42.1849 13.777 42.1988 13.7631C42.2517 13.7106 44.0765 14.2409 44.8109 14.5221C45.9034 14.9405 46.0837 14.9947 46.2164 14.9443C46.3053 14.9104 46.3483 14.6526 46.3915 13.8946Z"
        fill="url(#paint1_linear_5_3293)"
      />
      <path
        d="M23.3095 10.0021C22.6614 9.90224 22.6341 9.89052 22.9391 9.84394C23.5235 9.75463 24.9036 9.87635 25.8546 10.1011C28.0747 10.6255 30.095 11.9689 32.2514 14.3548L32.8243 14.9886L33.6439 14.8577C37.0966 14.3064 40.609 14.7446 43.5468 16.0932C44.355 16.4642 45.6293 17.2028 45.7885 17.3925C45.8392 17.453 45.9324 17.8424 45.9954 18.2579C46.2137 19.6952 46.1044 20.797 45.662 21.6199C45.4213 22.0677 45.4078 22.2096 45.5697 22.5929C45.6989 22.8987 46.0591 23.125 46.4158 23.1245C47.1459 23.1235 47.9317 21.9508 48.2958 20.319L48.4404 19.6708L48.7269 19.9933C50.2984 21.7626 51.5328 24.1756 51.7448 25.8931L51.8001 26.3409L51.5359 25.9338C51.0813 25.2333 50.6245 24.7564 50.0396 24.3718C48.9852 23.6785 47.8703 23.4425 44.9177 23.2879C42.251 23.1483 40.7417 22.922 39.2452 22.437C36.699 21.6121 35.4154 20.5134 32.3909 16.5701C31.0475 14.8186 30.2171 13.8496 29.3912 13.0692C27.5143 11.296 25.6702 10.366 23.3095 10.0021Z"
        fill="url(#paint2_linear_5_3293)"
      />
      <path
        d="M24.5684 18.484C23.2527 16.684 22.4387 13.9243 22.6149 11.8613L22.6694 11.2229L22.9689 11.2772C23.5313 11.3792 24.501 11.7378 24.955 12.0119C26.201 12.7639 26.7405 13.7539 27.2893 16.2962C27.45 17.0409 27.6609 17.8836 27.758 18.1689C27.9142 18.6281 28.5046 19.7007 28.9846 20.3973C29.3303 20.899 29.1007 21.1368 28.3364 21.0682C27.1693 20.9635 25.5883 19.8792 24.5684 18.484Z"
        fill="url(#paint3_linear_5_3293)"
      />
      <path
        d="M44.7942 31.8799C38.6457 29.4199 36.4802 27.2846 36.4802 23.6818C36.4802 23.1516 36.4986 22.7178 36.521 22.7178C36.5433 22.7178 36.7812 22.8927 37.0496 23.1066C38.2963 24.1003 39.6925 24.5248 43.5576 25.0851C45.832 25.4148 47.112 25.6812 48.2926 26.0703C52.0454 27.307 54.3672 29.8168 54.9209 33.2354C55.0818 34.2287 54.9875 36.0915 54.7266 37.0732C54.5206 37.8487 53.8922 39.2464 53.7255 39.3C53.6794 39.3149 53.634 39.1388 53.6221 38.8994C53.5589 37.6157 52.9062 36.366 51.8099 35.4299C50.5634 34.3656 48.8888 33.5182 44.7942 31.8799Z"
        fill="url(#paint4_linear_5_3293)"
      />
      <path
        d="M40.4777 32.9019C40.4007 32.4463 40.2671 31.8646 40.1808 31.6091L40.0239 31.1446L40.3153 31.4696C40.7186 31.9194 41.0372 32.495 41.3073 33.2615C41.5135 33.8466 41.5367 34.0206 41.5351 34.9713C41.5335 35.9047 41.5078 36.1004 41.3174 36.627C41.0173 37.4574 40.6448 38.0462 40.0198 38.6782C38.8967 39.8141 37.4527 40.443 35.3689 40.7039C35.0067 40.7491 33.951 40.8255 33.0229 40.8735C30.6839 40.9944 29.1445 41.2441 27.7613 41.7267C27.5624 41.7962 27.3848 41.8384 27.3668 41.8204C27.3108 41.7651 28.2526 41.2072 29.0305 40.8349C30.1274 40.3098 31.2193 40.0233 33.6656 39.6185C34.8741 39.4184 36.1221 39.1758 36.439 39.0792C39.4317 38.1674 40.9701 35.8144 40.4777 32.9019Z"
        fill="url(#paint5_linear_5_3293)"
      />
      <path
        d="M43.2963 37.876C42.4793 36.131 42.2918 34.4461 42.7393 32.8748C42.7872 32.7069 42.8642 32.5695 42.9105 32.5695C42.9568 32.5695 43.1496 32.673 43.3388 32.7995C43.7153 33.0514 44.4703 33.4755 46.4817 34.5653C48.9917 35.9251 50.4227 36.9781 51.3959 38.1812C52.2482 39.2348 52.7756 40.4347 53.0295 41.898C53.1732 42.7268 53.089 44.7212 52.875 45.5558C52.2001 48.1874 50.6317 50.2546 48.3946 51.4608C48.0668 51.6375 47.7726 51.7826 47.7408 51.7832C47.7089 51.7839 47.8284 51.4821 48.0062 51.1126C48.7587 49.5494 48.8444 48.0288 48.2754 46.3363C47.9271 45.3 47.2168 44.0354 45.7826 41.8983C44.1152 39.4135 43.7064 38.7522 43.2963 37.876Z"
        fill="url(#paint6_linear_5_3293)"
      />
      <path
        d="M20.202 47.2916C22.4836 45.3771 25.3226 44.0171 27.9085 43.5997C29.023 43.4198 30.8796 43.4912 31.9116 43.7537C33.5658 44.1743 35.0457 45.1166 35.8152 46.2392C36.5674 47.3364 36.8901 48.2925 37.226 50.4198C37.3585 51.259 37.5026 52.1017 37.5463 52.2925C37.7986 53.395 38.2895 54.2763 38.8979 54.7188C39.8642 55.4217 41.5282 55.4654 43.165 54.8309C43.4429 54.7232 43.684 54.6487 43.701 54.6655C43.7604 54.7241 42.9361 55.2724 42.3546 55.561C41.5722 55.9494 40.9501 56.0995 40.1234 56.0995C38.6242 56.0995 37.3796 55.3417 36.341 53.7964C36.1366 53.4923 35.6773 52.5814 35.3202 51.7723C34.2236 49.287 33.6822 48.5299 32.409 47.7013C31.301 46.9803 29.872 46.8512 28.7971 47.3749C27.385 48.0629 26.991 49.8561 28.0024 50.9924C28.4043 51.4441 29.1539 51.8337 29.7668 51.9094C30.9135 52.0511 31.8989 51.1843 31.8989 50.0338C31.8989 49.2869 31.61 48.8606 30.8827 48.5344C29.8893 48.089 28.8214 48.6096 28.8265 49.537C28.8288 49.9325 29.002 50.1808 29.401 50.3602C29.6569 50.4752 29.6628 50.4843 29.4541 50.4413C28.5426 50.2534 28.3291 49.1615 29.062 48.4366C29.9419 47.5663 31.7613 47.9503 32.3861 49.1382C32.6486 49.6372 32.679 50.6309 32.4502 51.2309C31.938 52.5739 30.4446 53.2801 28.9297 52.8958C27.8983 52.6341 27.4783 52.3508 26.2347 51.0779C24.0738 48.866 23.2349 48.4374 20.1197 47.9541L19.5227 47.8615L20.202 47.2916Z"
        fill="url(#paint7_linear_5_3293)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0628 7.70244C17.2792 16.406 22.2494 19.9969 22.8018 20.7556C23.2578 21.382 23.0862 21.9452 22.3049 22.3865C21.8704 22.6319 20.9772 22.8806 20.53 22.8806C20.0242 22.8806 19.8505 22.6867 19.8505 22.6867C19.5572 22.4103 19.392 22.4586 17.8859 19.8019C15.7949 16.5777 14.045 13.9031 13.9972 13.8583C13.8868 13.7548 13.8887 13.7583 17.6727 20.4862C18.2841 21.8884 17.7943 22.4032 17.7943 22.6029C17.7943 23.0092 17.6827 23.2227 17.1784 23.7817C16.3376 24.7138 15.9618 25.7611 15.6904 27.9286C15.3863 30.3583 14.531 32.0746 12.1608 35.012C10.7733 36.7315 10.5463 37.0467 10.1962 37.7397C9.75523 38.6125 9.63398 39.1013 9.58484 40.2034C9.53289 41.3686 9.63406 42.1213 9.9923 43.2353C10.3059 44.2107 10.6333 44.8547 11.4702 46.1428C12.1925 47.2544 12.6083 48.0805 12.6083 48.4037C12.6083 48.6608 12.6577 48.6611 13.7769 48.41C16.4552 47.809 18.63 46.7519 19.8532 45.4565C20.6102 44.6547 20.7879 44.212 20.7937 43.1132C20.7975 42.3945 20.772 42.2441 20.5765 41.8306C20.2583 41.1577 19.6789 40.5982 18.402 39.7307C16.7289 38.5942 16.0144 37.6792 15.817 36.4209C15.655 35.3884 15.8429 34.66 16.7685 32.7323C17.7265 30.737 17.9639 29.8867 18.1245 27.8755C18.2282 26.5761 18.3718 26.0637 18.7475 25.6523C19.1393 25.2234 19.4921 25.0782 20.4618 24.9465C22.0427 24.7319 23.0493 24.3255 23.8768 23.5678C24.5946 22.9105 24.8949 22.2771 24.941 21.3237L24.976 20.601L24.5749 20.1363C23.1222 18.4532 9.08995 6.37938 9.00055 6.37938C8.98146 6.37938 9.45947 6.9748 10.0628 7.70244ZM13.4238 41.5218C13.7522 40.9438 13.5777 40.2007 13.0283 39.8377C12.5092 39.4947 11.7028 39.6562 11.7028 40.1032C11.7028 40.2396 11.7787 40.3389 11.9498 40.4264C12.2378 40.5737 12.2587 40.7393 12.0321 41.0777C11.8026 41.4205 11.8211 41.7219 12.0844 41.9267C12.5086 42.2568 13.1092 42.0753 13.4238 41.5218Z"
        fill="url(#paint8_linear_5_3293)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9737 25.3256C25.2315 25.5521 24.5101 26.3337 24.2868 27.1533C24.1505 27.6533 24.2278 28.5303 24.4319 28.8012C24.7616 29.2387 25.0804 29.354 25.9436 29.3479C27.6338 29.3362 29.1031 28.6159 29.2739 27.7154C29.4139 26.9773 28.7686 25.9543 27.8797 25.5052C27.4211 25.2736 26.4456 25.1817 25.9737 25.3256ZM27.9495 26.8608C28.2101 26.4928 28.0961 26.0951 27.6528 25.8261C26.8086 25.3139 25.5321 25.7377 25.5321 26.5302C25.5321 26.9247 26.1978 27.3551 26.808 27.3551C27.2141 27.3551 27.7699 27.1144 27.9495 26.8608Z"
        fill="url(#paint9_linear_5_3293)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_3293"
          x1="24.5333"
          y1="64"
          x2="24.5333"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF1FF" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5_3293"
          x1="52.6875"
          y1="48.3169"
          x2="23.4667"
          y2="23.4669"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF4D2" />
          <stop offset="1" stopColor="#FF41F4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
